<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="4">
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            label-text="code"
            name="code"
            readonly
          />
        </b-col>
        <b-col md="4">
          <!-- date  -->
          <g-picker
           :value.sync="selectedItem.transactionDate"
           :disabled="!currentBranch.setDefaultDate"
           label-text="date"/>
        </b-col>
        <!-- employee  -->
        <b-col md="4" >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="employee"
              :options="employees"
              rules="required"
              label="arabicName"
              label-text="employee"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- transactionType  -->
        <b-col md="4" >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.transactionType"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="transactionType"
              :options="transactionsType"
              rules="required"
              label="arabicName"
              label-text="transactionType"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <g-field
            field="number"
            rules="required"
            :value.sync="selectedItem.value"
            type="number"
            label-text="value"
          />
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.paymentMethod"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="Payment Method"
              field="select"

              name="paymentMethod"
              :options="paymentWays"
              rules="required"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <!-- bank  -->
          <b-col md="4" v-if="selectedItem.paymentMethod == 'other'">
            <b-form-group>
              <g-field
                :value.sync="selectedItem.otherPayment"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="PaymentMethods"
                field="select"
                name="PaymentMethods"
                :options="payments"
                @change="(v) => {
                  selectedItem.bankId = v.bankId
                  }"
                rules="required"
                label="arabicName"
              />
            </b-form-group>
          </b-col>
          <!-- bank  -->
          <b-col md="4" v-if="selectedItem.paymentMethod == 'other'">
            <b-form-group>
              <g-field
                :value.sync="selectedItem.bankId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="bankName"
                field="select"
                name="bank1"
                disabled
                :options="Banks"
                rules="required"
                label="arabicName"
              />
            </b-form-group>
          </b-col>
      </b-row>
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
        <b-row>
          <b-col cols="12">
          <hr />
        </b-col>
        </b-row>
      <b-row>
      <b-col cols="12" class="d-flex justify-content-end">
          <b-button class="mx-1" type="submit" data-action-type="save" variant="primary" v-permission="$route.meta.permission">
            {{ $t('save') }}
          </b-button>
        </b-col>
    </b-row>
      <!-- </b-row> -->
    </g-form>
  </b-card>
</template>

<script>
import { paymentWays, transactionsTypes } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';

export default {
  props: ['id'],
  mixins: [
    reportMixin,
  ],
  data() {
    return {
      selectedItem: {
        transactionDate: '',
        voucherType: '',
      },
      transactionsType: transactionsTypes,
      employees: [],
      bankName: null,
      Banks: [],
      paymentMethods: [],
      transactionDate: this.today,
      paymentWays: paymentWays,
    };
  },
  watch: {
    'selectedItem.paymentMethod'(newval) {
      if (newval === 'safe') {
        this.selectedItem.bankId = null;
        this.selectedItem.paymentMethodId = null;
        this.bankName = null
      } else if (newval === 'other') {
        this.selectedItem.safeId = null;
      }
    },
  },
  computed: {
  },
  mounted() {
    this.selectedItem.transactionDate = this.today;
    this.loadObj();
    if (this.id > 0) {
      this.getData();
    }
  },
  methods: {
    loadObj() {
      this.getEmployees();
      this.getBanks();
    },
    getEmployees() {
        this.get({ url: 'employees' }).then((data) => {
          this.employees = data;
        })
    },
    getBanks() {
      this.get({ url: 'Banks' }).then((data) => {
        this.Banks = data;
      });
      this.get({ url: 'PaymentMethods/getAllNonElectronic' }).then((data) => {
        this.payments = data;
      });
    },
    getData() {
        this.get({
          url: 'FinancialCustodies',
          id: this.$route.params.id,
        }).then((data) => {
          this.selectedItem = data;
        })
    },
    save(type) {
      this.selectedItem.branchId = this.branchId;
        if (this.id > 0) {
          this.update({
            url: 'FinancialCustodies',
            data: this.selectedItem,
            id: this.id,
          })
            .then(() => {
              this.doneAlert({ text: this.$t('updatedSuccessfully') });
              if (type === 'saveAndPrint') this.print(this.id);
              if (type === 'cancel') {
                window.location.reload();
              } else {
                this.$router.push({ name: 'financialCustody' });
              }
            });
        } else {
          this.create({
            url: 'FinancialCustodies',
            data: this.selectedItem,
          })
            .then((data) => {
              this.doneAlert({
                text: this.$t('savedSuccessfullyWithCode', {
                  code: data.code,
                }),
              });
              if (type === 'saveAndPrint') this.print(data.id);
              this.$router.push({ name: 'financialCustody' });
            });
        }
    }
  },
};
</script>

<style></style>
